import service from '@/until/api'
//分页
export function _GetTableList(data) {
  return service({
    url: '/protocol-nacos/carAlarm/centerHistory',
    method: 'POST',
    data
  })
}

//导出报警记录
export function _GetWarnLogFile(data) {
  return service({
    url: '/file-nacos/excelDownload/exportWarningRecord',
    method: 'POST',
    responseType: 'blob',
    data
  })
}