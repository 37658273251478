<template>
  <div>
    <div>
      <el-button
        class="centerBtn mb20"
        @click="getWarnLogFile"
        :loading="warnLongLoading"
      >
        导出报警记录
      </el-button>
    </div>
    <!-- 搜索表单 -->
    <el-form inline>
      <el-form-item>
        <el-select
          class="centerSelInput"
          placeholder="请选择园区"
          v-model="searchData.parkId"
        >
          <el-option
            v-for="item in parkList"
            :key="item.parkId"
            :label="item.parkName"
            :value="item.parkId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          v-model="timeValue"
          :clearable="false"
          @change="getStartEndTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <TreeSelect
          :selectClass="'centerSelInput'"
          :options="options"
          :treeProps="props"
          :placeholder="'请选择车辆类型'"
          multiple
          v-model="searchData.carTypeIds"
        ></TreeSelect>
      </el-form-item>
      <el-form-item>
        <el-input
          class="centerInput"
          placeholder="请输入车辆编号"
          v-model="searchData.carNumber"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn" type="button" @click="searchBtn">
          搜索
        </button>
      </el-form-item>
      <el-form-item style="margin-right: 0px">
        <button class="centerBtn resetbtn" type="button" @click="resetBtn">
          重置
        </button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchData.page"
      :size="searchData.size"
    >
    </Table>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { _CarType } from '@/api/sass/index.js'
import TreeSelect from '@/components/treeSelect.vue'
import { _GetAllPark } from '@/api/systemManagement/roleManage'
import {
  _GetTableList,
  _GetWarnLogFile
} from '@/api/equipmentMonitoring/carWarnLog.js'
import { warningControllerDict } from '@/until/dictionaries.js'
import { saveAs } from 'file-saver'
export default {
  name: '',
  components: {
    Table, //Table组件
    TreeSelect //树形选择器
  },
  data() {
    return {
      props: { id: 'id', label: 'typeName', children: 'children' },
      warnLongLoading: false,
      options: [],
      parkList: [],
      tableData: [],
      columns: [
        {
          label: '报警事件',
          prop: 'alarmEventDesc'
        },
        {
          label: '车辆编号',
          prop: 'carNumber'
        },
        {
          label: '车辆子类型',
          prop: 'carTypeName'
        },
        {
          label: '所属园区',
          prop: 'parkName'
        },
        {
          label: '报警等级',
          prop: 'alarmLevel',
          render: (h, params) => {
            let label = '-'
            switch (params.row.alarmLevel) {
              case 1:
                label = '一级报警'
                break
              case 2:
                label = '二级报警'
                break
              case 3:
                label = '三级报警'
                break
            }
            return h('span', label)
          }
        },
        {
          label: '报警发生时间',
          prop: 'alarmTriggerTime'
        },
        {
          label: '报警控制器',
          prop: 'vehicleWarningController',
          // render: (h, params) => {
          //   let label = '-'
          //   for (let i = 0; i < warningControllerDict.length; i++) {
          //     if (
          //       warningControllerDict[i].value ==
          //       params.row.vehicleWarningController
          //     ) {
          //       label = warningControllerDict[i].label
          //       break
          //     }
          //   }
          //   return h('span', label)
          // }
        }
      ],
      total: 0,
      loading: false,
      searchData: {
        page: 1,
        size: 10,
        warningStartTime: null, // 车辆告警发生开始时间
        warningEndTime: null, // 车辆告警发生结束时间
        parkId: null, // 园区
        carTypeIds: [], // 车辆类型
        carNumber: null // 车辆编号
      },
      timeValue: []
    }
  },
  created() {
    this.getTableData()
    this.getAllCarType()
    this.getAllPark()
  },
  methods: {
    // 获取表格信息
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchData.page = pagination.page
          this.searchData.size = pagination.limit
        }
      }
      try {
        this.loading = true
        let { data } = await _GetTableList(this.searchData)
        this.tableData = data.list
        this.total = data.total
      } finally {
        this.loading = false
      }
    },
    // 获取车辆类型
    async getAllCarType() {
      try {
        let { data } = await _CarType()
        this.options = data.list
      } catch (error) {
        this.$message.error('获取车辆类型失败！')
      }
    },
    // 获取全部园区
    async getAllPark() {
      try {
        let { data } = await _GetAllPark()
        this.parkList = data
      } catch (error) {
        this.$message.error('获取园区失败！')
      }
    },
    // 时间数组切分
    getStartEndTime() {
      if (this.timeValue.length) {
        this.searchData.warningStartTime = this.timeValue[0]
        this.searchData.warningEndTime = this.timeValue[1]
      }
    },
    // 重置
    resetBtn() {
      this.timeValue = []
      Object.assign(this.$data.searchData, this.$options.data().searchData)
      this.getTableData()
    },
    // 搜索
    searchBtn() {
      this.searchData.page = 1
      console.log(this.searchData)
      this.getTableData()
    },
    // 导出报警记录
    async getWarnLogFile() {
      let obj = JSON.parse(JSON.stringify(this.searchData))
      delete obj.page
      delete obj.size
      this.warnLongLoading = true
      try {
        let res = await _GetWarnLogFile(obj)
        let blob = new Blob([res])
        saveAs(blob, '报警记录.xlsx')
      } catch (error) {
        this.$message('下载失败')
      } finally {
        this.warnLongLoading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-date-editor {
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid white !important;
  font-size: 14px;
  border-radius: 0px;
}
</style>
