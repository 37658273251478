/**
 * @msg: 协议管理、数据看板 报警控制器字典
 */
export const warningControllerDict = [
  {
    value: '自动驾驶控制器',
    label: '自动驾驶控制器'
  },
  {
    value: '远程驾驶控制器',
    label: '远程驾驶控制器'
  },
  {
    value: '电子助力器',
    label: '电子助力器'
  },
  {
    value: '电机控制器',
    label: '电机控制器'
  },
  {
    value: '电池管理系统',
    label: '电池管理系统'
  },
  {
    value: '电子驻车',
    label: '电子驻车'
  },
  {
    value: '防抱死系统',
    label: '防抱死系统'
  },
  {
    value: '电子制动',
    label: '电子制动'
  },
  {
    value: '电子车身稳定系统',
    label: '电子车身稳定系统'
  },
  {
    value: '电子手刹',
    label: '电子手刹'
  },
  {
    value: 'OBU',
    label: 'OBU'
  }
]
